import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Theme, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system/styleFunctionSx'
import React from 'react'
import LocationContext from '../../shared/contexts/location'
import { ExternalProvider, Options } from '../options'
import ProviderIcon from './provider-icon'
import bizzkitIcon from '../../assets/img/bizzkit.svg'

const ProviderSelection: React.FC<{ options: Options }> = (props) => {
    const location = React.useContext(LocationContext)
    const theme = useTheme()

    const redirectToProvider = (externalProvider: ExternalProvider) => {
        const provider = encodeURIComponent(
            externalProvider.authenticationScheme
        )
        const returnUrl = props.options.returnUrl
            ? encodeURIComponent(props.options.returnUrl)
            : ''
        location.href = `/external/challenge?provider=${provider}&returnUrl=${returnUrl}`
    }

    const redirectToLocalLogin = () => {
        const query =
            props.options.returnUrl === null
                ? ''
                : `?returnUrl=${encodeURIComponent(props.options.returnUrl)}`

        location.href = `/account/locallogin${query}`
    }

    const displayText = !props.options.backgroundImageUrl
        ? 'Sign in to Bizzkit, the only e-commerce platform which combines PIM, DAM and CMS'
        : ''

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${bizzkitIcon})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '50px',
                }}
            ></Box>
            <Box my={3} textAlign='center'>
                {displayText}
            </Box>
            <List>
                {props.options.visibleExternalProviders.map((provider) => (
                    <ListItem key={provider.displayName}>
                        <Button
                            startIcon={<ProviderIcon provider={provider} />}
                            variant='outlined'
                            fullWidth
                            onClick={() => redirectToProvider(provider)}
                            sx={providerLabelStyles(theme)}
                        >
                            Sign In With {provider.displayName}
                        </Button>
                    </ListItem>
                ))}
                {props.options.enableLocalLogin && (
                    <ListItem>
                        <Button
                            startIcon={<ProviderIcon />}
                            variant='outlined'
                            fullWidth
                            onClick={redirectToLocalLogin}
                            sx={providerLabelStyles(theme)}
                        >
                            Sign In With E-mail
                        </Button>
                    </ListItem>
                )}
            </List>
        </>
    )
}

const providerLabelStyles = (theme: Theme): SxProps<Theme> => ({
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
})

export default ProviderSelection
