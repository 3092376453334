import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(16),
    },
}))

const AuthLayout: React.FC<{
    backgroundImageUrl?: string | null
    logoImageUrl?: string | null
}> = (props) => {
    const theme = useTheme()

    const backgroundStyle = !props.backgroundImageUrl
        ? { backgroundColor: theme.palette.primary.main }
        : { backgroundImage: `url(${props.backgroundImageUrl})` }

    return (
        <Box
            sx={{
                ...backgroundStyle,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                position: 'fixed',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box>
                {props.logoImageUrl ? (
                    <Box
                        sx={{
                            backgroundImage: `url(${props.logoImageUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center center',
                            height: '100px',
                            margin: theme.spacing(16),
                            [theme.breakpoints.down('sm')]: {
                                height: '50px',
                                margin: theme.spacing(8),
                            },
                        }}
                    ></Box>
                ) : undefined}
                <Card
                    raised={true}
                    sx={{
                        margin: '50px 0px 0px 0px',
                        [theme.breakpoints.down('md')]: {
                            margin: '25px 0px 0px 0px',
                        },
                        [theme.breakpoints.down('sm')]: {
                            margin: '0px',
                        },
                    }}
                >
                    <CardContent
                        sx={{
                            [theme.breakpoints.down('lg')]: {
                                padding: '32px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                padding: '16px',
                            },
                        }}
                    >
                        <StyledBox>{props.children}</StyledBox>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}

export default AuthLayout
